@charset "UTF-8";

// rem フォント指定
// -----------------------------------------------------------
//    $size:    適用したいフォントサイズ（px）
//    $context: 継承しているフォントサイズ。デフォルトで $baseFontSize を継承するので、基本的に指定不要（px）
//
//    使用例：
//    p.foo {
//        @include rem(14, 16);  <= 16px を継承している p 要素に 1.4rem を指定（IE8 以下には代替として％指定を適用）
//    }
// -----------------------------------------------------------
$baseFontSize: 16px;

@mixin rem($size, $context: $baseFontSize) {
    $size: nounit($size);
    $context: nounit($context);
    font-size: fz($size, $context);
    font-size: ($size / $context) + rem;
}
