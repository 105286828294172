.cmn-404-message {
  margin: 0;
  text-align: center;
  @include rem(19);
  font-weight: bold;

  @include below($sm) {
    margin: 1em 0 0;
    @include rem(17);
    text-align: left;
  }
}