.cmn-404-nav {
  margin: 30px 0 0;
  background: #f3f3f3;
  padding: 33px 50px 40px;
  border-radius: 4px;

  @include below($sm) {
    padding: 20px;
  }

  > .nav {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    line-height: 1.2;

    @include below($sm) {
      flex-direction: column;
    }

    > li {
      margin: 0 10px;
      padding: 0;
      list-style: none;

      @include below($sm) {
        margin: 7px 0;
      }

      > a {
        display: block;
        border: 1px solid #d0d0d0;
        background-image: linear-gradient(to bottom, #fff 0%, #efeff1 100%);
        padding: 12px 45px;
        min-width: 190px;
        text-align: center;
        color: inherit;
        border-radius: 3px;
        text-decoration: none;

        @include below($sm) {
          min-width: 0;
        }

        &:hover,
        &:active,
        &:focus {
          color: #df0523;
          text-decoration: none;
        }

        &::before {
          content: '';
          display: inline-block;
          background: url("../img/arrow.png") 0 0 no-repeat;
          width: 6px;
          height: 10px;
          background-size: 6px 10px;
          vertical-align: middle;
          margin-right: 9px;
          position: relative;
          top: -2px;
        }
      }
    }
  }

  > .search {
    margin: 20px 0 0;

    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }

    legend {
      display: none;
    }

    .block {
      display: flex;
      border: 5px solid #ddd;
      border-radius: 3px;
      background: #ddd;

      > input[type="text"] {
        border: 0;
        padding: 3px 6px;
        flex-grow: 1;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      > input[type="image"] {
        flex-shrink: 0;
        border-radius: 0;
      }
    }
  }
}